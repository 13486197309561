<template>
    <div class="analitics">
        <v-row style="max-width: 1200px" class="mx-auto">
            <v-col cols="12" sm="6" md="3">
                <v-card class="mx-auto elevation-6" max-width="240" color="#2196F3" dark>
                    <v-list-item two-line>

                        <v-list-item-content>
                            <div class="overline mb-1">Заказы</div>

                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1" v-bind="attrs" v-on="on">
                                        {{ ordersCountToday }}
                                    </v-list-item-title>
                                </template>
                                <span>Все заказы</span>
                            </v-tooltip>

                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="40">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on">
                                        <v-icon size="medium">mdi-check-bold</v-icon> {{ ordersCountComplete }}
                                    </span>
                                </template>
                                <span>Доставленные заказы</span>
                            </v-tooltip>
                        </v-list-item-avatar>

                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card class="mx-auto elevation-6" max-width="240" color="#DC3546" dark>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline mb-1">Партнеры</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1" v-bind="attrs" v-on="on">
                                        {{ partners.length }}
                                    </v-list-item-title>
                                </template>
                                <span>Всего партнеров онлайн</span>
                            </v-tooltip>

                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="40">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on">
                                        <v-icon size="medium">mdi-check-bold</v-icon> {{ partnersTodayCount }}
                                    </span>
                                </template>
                                <span>Партнеров в работе</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card class="mx-auto elevation-6" max-width="240" color="#4CAF50" dark>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <div class="overline mb-1">Курьеры</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="tooltip-text headline mb-1" v-bind="attrs" v-on="on">
                                        {{ couriers.length }}
                                    </v-list-item-title>
                                </template>
                                <span>Всего курьеров онлайн</span>
                            </v-tooltip>

                            <v-list-item-subtitle>сейчас</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar size="40">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on">
                                        <v-icon size="medium">mdi-check-bold</v-icon> {{ couriersTodayCount }}
                                    </span>
                                </template>
                                <span>Курьеров в работе</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-card class="mx-auto elevation-6" max-width="240" color="#FF9800" dark>
                    <v-list-item two-line>

                        <v-list-item-content>
                            <div class="overline mb-1">Выручка</div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-title class="headline mb-1 tooltip-text" v-bind="attrs" v-on="on">
                                        {{ profitToday }} ₽
                                    </v-list-item-title>
                                </template>
                                <span>Всего выручка за сегодня</span>
                            </v-tooltip>
                            <v-list-item-subtitle>за сегодня</v-list-item-subtitle>
                        </v-list-item-content>
                        
                        <v-list-item-avatar size="60">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="tooltip-text" v-bind="attrs" v-on="on">
                                        {{ profitDiff }} ₽
                                    </span>
                                </template>
                                <span>Выручка по сравнению с предыдущим днем</span>
                            </v-tooltip>
                        </v-list-item-avatar>
                        
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>

        <v-row style="max-width: 1200px" class="mx-auto">
            <v-col cols="12" sm="12" md="6" lg="4">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по статусам</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :centerLabel="{ text: getOrdersCountPercent(), title: 'Процент доставленных заказов к общему количеству' }" :chartData="chartDataOrders" :colors="orderStatusColorsHtml()" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по партнерам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataPartners" :colors="colorsHtml" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar class="mb-2" height="50" color="cyan darken-1 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Обзор заказов по курьерам, top-20</v-toolbar-title>
                    </v-toolbar>
                    <Donut class="order-chart" :chartData="chartDataCouriers" :colors="colorsHtml" />
                </v-card>
            </v-col>
        </v-row>

        <v-row style="max-width: 1800px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto pb-3 elevation-6">
                    <v-toolbar class="mb-2" height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">Последние 20 заказов</v-toolbar-title>
                    </v-toolbar>
                    <v-simple-table dense>
                        <template v-slot:default>

                            <thead>
                                <tr>
                                    <th class="text-left">Сист.</th>
                                    <th class="text-left">Название</th>
                                    <th class="text-left">Статус</th>
                                    <th class="text-left">Гость</th>
                                    <th class="text-left">Адрес доставки</th>
                                    <th class="text-left">Цена</th>
                                    <th class="text-left">Курьер</th>
                                    <th class="text-left">Создан</th>
                                    <th class="text-left">Забрать</th>
                                    <th class="text-left">Доставить</th>
                                    <th class="text-left">Забран</th>
                                    <th class="text-left">Доставлен</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="item in orders.slice(0, 20)" :key="item.id">

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <v-avatar size="24">
                                                  <img
                                                    alt="source"
                                                    :src="iconSource(item)"
                                                  >
                                                </v-avatar>
                                            </td>
                                        </template>
                                        <span>{{ tooltipSource(item) }}</span>
                                    </v-tooltip>

                                    <tdFade bold :data="item.name" />

                                    <td>
                                        <v-chip :color="orderStatusColorHtml(item.status)" text-color="white">
                                            <v-icon size="small" class="mr-1">{{ orderStatusIcon(item.status) }}</v-icon>
                                            {{ orderStatusStr(item.status) }}
                                        </v-chip>
                                    </td>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                 <tdFade :data="getGuestStr(item.guest).substr(0, 24)" />
                                            </td>
                                        </template>
                                        <span>{{ getGuestStr(item.guest) }}</span>
                                    </v-tooltip>

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on" :style="item.fee == 0 || item.fee == $store.state.settings.price_default ? 'color: red' : ''">
                                                 <tdFade :data="item.finish_addr.substr(0, 24)" />
                                            </td>
                                        </template>
                                        <span>{{ item.finish_addr }}</span>
                                    </v-tooltip>

                                    <td :style="item.fee == 0 || item.fee == $store.state.settings.price_default ? 'color: red' : ''">
                                        <tdFade :data="item.fee" />
                                    </td>

                                    <td class="d-flex align-center">
                                        <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                          <img
                                            alt="courier"
                                            :src="`${ config.api.delivery + 'user_avatar?tid=' + item.courier_tid }`"
                                          >
                                        </v-avatar>
                                        <tdFade bold :data="item.courier_name" />
                                        <!-- <tdFade bold :data="courierNameGet(item.courier_tid)" /> -->
                                    </td>

                                    <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <td class="tooltip-text" v-bind="attrs" v-on="on">
                                                <tdFade :data="item.mode.split ? item.mode.split(' ')[0] : item.mode" />
                                                <tdFade :data="item.mode_time.substr(0, 32)" />
                                            </td>
                                        </template>
                                        <span>{{ item.mode_time }}</span>
                                    </v-tooltip> -->

                                    <tdFade :data="timeStrGet(item.date) + ' ' +  item.mode.split(' ')[0]" />

                                    <tdFade :data="timeStrGet(item.mode_ready_time)" />

                                    <tdFade :data="timeStrGet(item.mode_order_time)" />

                                    <td>
                                        <span class="mr-2">{{ timeStrGet(item.date_take) }}</span>
                                        <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.mode_ready_time)" x-small label text-color="white">
                                            {{ timeError(item.date_take, item.mode_ready_time) }}
                                        </v-chip>
                                    </td>

                                    <td>
                                        <span class="mr-2">{{ timeStrGet(item.date_complete) }}</span>
                                        <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.mode_order_time)" x-small label text-color="white">
                                            {{ timeError(item.date_complete, item.mode_order_time) }}
                                        </v-chip>
                                    </td>

                                </tr>
                            </tbody>

                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    import Donut from './Donut.vue'
    import TdFade from './TdFade.vue'
    import 'chartjs-plugin-labels'
    import moment from 'moment-timezone'

    moment.locale('ru')
    moment.tz.setDefault(config.timezoneString)

    export default {
        data() {
            return {
                config,
                loaded: false,
                chartDataOrders: {},
                chartDataPartners: {},
                chartDataCouriers: {},
                ordersCountToday: '--',
                ordersCountComplete: '--',
                partnersTodayCount: '--',
                couriersTodayCount: '--',
                orders: [],
                partners: [],
                couriers: [],
                users: [],
                partnersToday: [],
                partnersTodayCounts: [],
                couriersToday: [],
                couriersTodayCounts: [],
                couriersSelected: [],
                labelsSelected: [],
                profitToday: 0,
                profitYesterday: 0,
                profitDiff: 0,

                orderStatuses: [],
                timer: null,
                colorsHtml: [
                    '#2196F3', // blue
                    '#F44336', // red
                    '#4CAF50', // green
                    '#FF9800', // orange
                    '#9C27B0', // purple
                    '#3F51B5', // indigo
                    '#00BCD4', // cyan
                    '#FFEB3B', // yellow
                    '#795548', // brown
                    '#E91E63', // pink
                    '#673AB7', // deep-purple
                    '#03A9F4', // light-blue
                    '#009688', // teal
                    '#8BC34A', // light-green
                    '#FF8A65', // deep-orange lighten-2
                    '#B388FF', // deep-purple accent-1
                    '#84FFFF', // cyan accent-1
                    '#607D8B', // blue-grey
                    '#A1887F', // brown lighten-2
                    '#9E9E9E', // grey
                    '#9E9E9E', // grey
                    '#9E9E9E', // grey
                ],
            }
        },
        components: {
            Donut,
            TdFade,
        },
        mounted() {
            console.log('!!! mounted !!!')

            this.updateData()
            this.timer = setInterval(() => {
                this.updateData()
            }, 4000)
        },

        beforeDestroy() {
            console.log('!!! beforeDestroy !!!')
            if (this.chart) {
                this.chart.dispose()
            }
        },

        destroyed() {
            console.log('!!! destroyed !!!')
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {},
        methods: {
            getOrdersCountPercent() {
                return this.ordersCountToday > 0 ? Math.round((this.ordersCountComplete / this.ordersCountToday) * 100) + '%' : ''
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 15) : guest
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            partnerNameGet: function(partner_tid) {
                let partner_name = '--'
                const partner = this.partners.find(partner => partner.tid == partner_tid)
                if (partner) {
                    partner_name = partner.partner_name
                }
                return partner_name
            },
            courierNameGet: function(courier_tid) {
                let courier_name = '--'
                const courier = this.users.find(user => user.tid == courier_tid)
                if (courier) {
                    courier_name = courier.tname
                }
                return courier_name
            },
            fillDataOrders() {
                this.chartDataOrders = {
                    labels: this.orderStatusLabels(),
                    disabled: [],
                    datasets: [
                        {
                            backgroundColor: this.orderStatusColorsHtml(),
                            data: this.orderStatuses,
                        },
                    ],
                }
            },
            fillDataPartners() {
                this.chartDataPartners = {
                    labels: this.partnersLabels(),
                    disabled: [],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.partnersTodayCounts,
                        },
                    ],
                }
            },
            fillDataCouriers() {
                const labels = this.couriersLabels()
                this.chartDataCouriers = {
                    labels: labels[0],
                    disabled: labels[1],
                    datasets: [
                        {
                            backgroundColor: this.colorsHtml,
                            data: this.couriersTodayCounts,
                        },
                    ],
                }
            },
            partnersLabels() {
                const res = []
                this.partnersToday.forEach((partner_tid, i) => {
                    res[i] = this.partnerNameGet(partner_tid).substr(0, 20)
                })
                return res
            },
            couriersLabels() {
                const res = [[], []]
                this.couriersToday.forEach((courier_tid, i) => {
                    res[0][i] = this.courierNameGet(courier_tid).substr(0, 20)
                    res[1][i] = this.couriers.findIndex(courier => courier.tid == courier_tid) == -1
                })
                return res
            },
            orderStatusLabels() {
                const res = [[], []]
                for (let i = 0; i < 6; i++) {
                    res[i] = this.orderStatusStr(i)
                }
                return res
            },
            orderStatusColorsHtml() {
                return [this.orderStatusColorHtml(0), this.orderStatusColorHtml(1), this.orderStatusColorHtml(2), this.orderStatusColorHtml(3), this.orderStatusColorHtml(4), this.orderStatusColorHtml(5)]
            },
            updateData() {

                this.$store
                    .dispatch('orders_date', {
                        partner: 0,
                        courier: 0,
                        date1: moment().format('YYYY-MM-DD'),
                        date2: moment().format('YYYY-MM-DD') + ' 23:59:59',
                        mode: 0,
                        deliveryTime: true,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.orders = res.data.orders.reverse()

                            console.log('orders_today', this.orders)
                            const ordersComplete = this.orders.map(order => order.state == config.order.status.complete)
                            this.ordersCountToday = ordersComplete.length
                            this.orderStatuses[config.order.status.forming] = 0
                            this.orderStatuses[config.order.status.new] = 0
                            this.orderStatuses[config.order.status.passed] = 0
                            this.orderStatuses[config.order.status.way_partner] = 0
                            this.orderStatuses[config.order.status.way_guest] = 0
                            this.orderStatuses[config.order.status.complete] = 0
                            const partnersTodaySet = new Set()
                            const couriersTodaySet = new Set()
                            this.orders.forEach(order => {
                                if (order.partner_tid != null) {
                                    partnersTodaySet.add(order.partner_tid)
                                }
                                if (order.courier_tid != null) {
                                    couriersTodaySet.add(order.courier_tid)
                                }
                            })
                            this.partnersTodayCount = partnersTodaySet.size
                            this.couriersTodayCount = couriersTodaySet.size
                            this.partnersToday = Array.from(partnersTodaySet)
                            this.couriersToday = Array.from(couriersTodaySet)
                            this.partnersToday.forEach((partner_tid, i) => {
                                this.partnersTodayCounts[i] = 0
                            })
                            this.couriersToday.forEach((courier_tid, i) => {
                                this.couriersTodayCounts[i] = 0
                            })

                            // let i = 0
                            // console.log("hello")
                            // console.log("hello")
                            // console.log("hello")
                            this.orders.forEach(order => {
                            // console.log("hello")
                                this.orderStatuses[order.status]++
                                this.partnersToday.forEach((partner_tid, i) => {
                                    if (partner_tid == order.partner_tid) {
                                        this.partnersTodayCounts[i]++
                                    }
                                })
                                this.couriersToday.forEach((courier_tid, i) => {
                                    if (courier_tid == order.courier_tid) {
                                        this.couriersTodayCounts[i]++
                                    }
                                })
                                // i++
                                // if (order.status == config.order.status.forming) {
                                //     console.log(i, order.name, order.id)
                                // }
                                // if (i == 51) {
                                //     console.log(order)
                                // }
                            })
                            // console.log('this.orderStatuses[config.order.status.passed]', this.orderStatuses[config.order.status.passed])
                            let mapped = this.partnersTodayCounts.map((el, i) => {
                                return { index: i, value: el }
                            })
                            mapped.sort((a, b) => b.value - a.value)
                            mapped = mapped.slice(0,20)
                            this.partnersToday = mapped.map(el => this.partnersToday[el.index])
                            this.partnersTodayCounts = mapped.map(el => this.partnersTodayCounts[el.index])

                            mapped = this.couriersTodayCounts.map((el, i) => {
                                return { index: i, value: el }
                            })
                            mapped.sort((a, b) => b.value - a.value)
                            mapped = mapped.slice(0,20)
                            this.couriersToday = mapped.map(el => this.couriersToday[el.index])
                            this.couriersTodayCounts = mapped.map(el => this.couriersTodayCounts[el.index])


                            this.ordersCountComplete = this.orderStatuses[config.order.status.complete]

                            this.profitToday = 0
                            for (var order of this.orders) {
                                if (order.date_complete) {
                                    const fee = parseInt(order.fee)
                                    if (fee) this.profitToday += fee
                                    // this.profitToday += parseInt(order.fee)
                                }
                            }
                            this.$store
                                .dispatch('orders_date', {
                                    partner: 0,
                                    courier: 0,
                                    date1: moment().subtract(1, 'd').format('YYYY-MM-DD'),
                                    date2: moment().subtract(1, 'd').format('YYYY-MM-DD') + ' 23:59:59',
                                    mode: 0,
                                    deliveryTime: true,
                                })
                                .then(res => {
                                    if (res.data.success) {
                                        const orders_yesterday = res.data.orders
                                        console.log('orders_yesterday', orders_yesterday)
                                        this.profitYesterday = 0
                                        for (var order of orders_yesterday) {
                                            if (order.date_complete) {
                                                this.profitYesterday += parseInt(order.fee)
                                            }
                                        }
                                        this.profitDiff = this.profitToday - this.profitYesterday
                                        if (this.profitDiff > 0) {
                                            this.profitDiff = '+' + this.profitDiff
                                        }
                                    }
                                })
                        } else {
                            this.ordersCountToday = 0
                            this.ordersCountComplete = 0
                        }
                        this.loaded = true
                        this.fillDataOrders()
                        this.fillDataPartners()
                        this.fillDataCouriers()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                this.$store
                    .dispatch('partners')
                    .then(res => {
                        if (res.data.success) {
                            this.partners = res.data.partners
                            console.log('partners', this.partners)
                        }
                        this.fillDataPartners()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                this.$store
                    .dispatch('couriers_get')
                    .then(res => {
                        if (res.data.success) {
                            this.couriers = res.data.couriers.filter(courier => courier.enable)
                            console.log('couriers', this.couriers)
                        }
                        this.fillDataCouriers()
                    })
                    .catch(err => {
                        console.log(err)
                    })
                this.$store
                    .dispatch('users_get')
                    .then(res => {
                        if (res.data.success) {
                            this.users = res.data.users
                            console.log('users', this.users)
                        }
                        this.fillDataCouriers()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            timeError(time1, time2) {
                let diff = ''
                if (time1 && time2) {
                    diff = moment(time1).diff(time2, "minutes")
                }
                let time = ''
                let sign = '+'
                if (diff < 0) {
                    sign = '-'
                    diff = -diff
                }
                let mins = 0
                let hours = 0
                for (; diff > 60; diff -= 60) {
                    hours++
                }
                mins = diff
                time += sign
                if (hours) {
                    time += hours + ' ч '
                }
                if (mins) {
                    time += mins + ' м '
                }
                return time
            },
            timeColorHtml(time1, time2) {
                let color
                if (time1 && time2) {
                    let diff = moment(time1).diff(time2, "minutes")
                    if (diff > 10) {
                        color = '#F44336' // red
                    } else {
                        color = '#4CAF50' // green
                    }
                } else {
                    color = '#607D8B' // blue-grey
                }
                return color
            },
            timeStrGet(date) {
                const isToday = moment().isSame(moment(date), 'day')
                let dateRes = ''
                if (isToday) {
                    dateRes = moment(date).format('HH:mm')
                } else {
                    dateRes = moment(date).format('D MMM HH:mm')
                }
                if (dateRes == 'Invalid date') dateRes = ''
                return dateRes
            },
            iconSource(order) {
                let icon = ''
                if (order.iikoOrderId) {
                    icon = `${ require('../../assets/images/iiko-48.jpg') }`
                } else {
                    icon = `${ require('../../assets/images/telegram-48.jpg') }`
                }
                return icon
            },
            tooltipSource(order) {
                let tooltip = ''
                if (order.iikoOrderId) {
                    tooltip = 'iiko'
                } else {
                    tooltip = 'Telegram bot'
                }
                return tooltip
            },
            orderStatusColorHtml(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = '#9C27B0' // purple
                        break
                    case config.order.status.way_partner:
                        color = '#FF9800' // orange
                        break
                    case config.order.status.way_guest:
                        color = '#2196F3' // blue
                        break
                    case config.order.status.complete:
                        color = '#4CAF50' // green
                        break
                    case config.order.status.forming:
                        color = '#607D8B' // blue-grey
                        break
                    case config.order.status.passed:
                        color = '#F44336' // red
                        break
                }
                return color
            },
            orderStatusColor(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = 'purple'
                        break
                    case config.order.status.way_partner:
                        color = 'orange'
                        break
                    case config.order.status.way_guest:
                        color = 'blue'
                        break
                    case config.order.status.complete:
                        color = 'green'
                        break
                    case config.order.status.forming:
                        color = 'grey'
                        break
                    case config.order.status.passed:
                        color = 'red'
                        break
                }
                return color + '--text'
            },
            orderStatusIcon(sta) {
                let icon = 'mdi-question'
                switch (sta) {
                    case config.order.status.new:
                        icon = 'mdi-flash'
                        break
                    case config.order.status.way_partner:
                        icon = 'mdi-run-fast'
                        break
                    case config.order.status.way_guest:
                        icon = 'mdi-run-fast'
                        break
                    case config.order.status.complete:
                        icon = 'mdi-check-bold'
                        break
                    case config.order.status.forming:
                        icon = 'mdi-account-question'
                        break
                    case config.order.status.passed:
                        icon = 'mdi-arrow-right-bold'
                        break
                }
                return icon
            },
            orderStatusStr(sta) {
                let status = 'Неизвестно'
                switch (sta) {
                    case config.order.status.new:
                        status = 'Новый'
                        break
                    case config.order.status.way_partner:
                        status = 'В пути к партнеру'
                        break
                    case config.order.status.way_guest:
                        status = 'В пути к гостю'
                        break
                    case config.order.status.complete:
                        status = 'Доставлен'
                        break
                    case config.order.status.forming:
                        status = 'Создается'
                        break
                    case config.order.status.passed:
                        status = 'Передан курьеру'
                        break
                }
                return status
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .analitics
        height 100%
        .text
            padding 0
        .v-card
            cursor default
        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                td
                    cursor default
                    .text
                        padding 0 8px

                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px

        b
            font-weight 500

</style>
