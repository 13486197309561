<template>
    <div style="height:100%">
        <v-row>
            <v-col cols="8" class="pa-0 pl-2">
                <div class="pie-wrapper">
                    <Pie :chartData="chartData" :options="options" />
                    <div v-if="centerLabel && chartData.datasets" class="center-wrapper">
                        <v-tooltip top v-if="centerLabel.title">
                            <template v-slot:activator="{ on, attrs }">
                                <div class="center-label tooltip-text" v-bind="attrs" v-on="on">{{ centerLabel.text }}</div>
                            </template>
                            <span>{{ centerLabel.title }}</span>
                        </v-tooltip>
                        <div v-if="!centerLabel.title" class="center-label">{{ centerLabel.text }}</div>
                    </div>
                </div>
            </v-col>
            <v-col cols="4" class="pa-0">
                <div class="legend">
                    <li v-for="(label, i) in chartData.labels" :key="i" class="list-item" :class="{'list-item-disabled': chartData.disabled[i]}">
                        <div class="color-box" :style="{ 'background-color': colors[i] }" />
                        {{ label }}
                    </li>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Pie from './Pie.js'

    export default {
        data() {
            return {
                options: {
                    cutoutPercentage: 60,
                    segmentShowStroke: true,
                    segmentStrokeColor: '#fff',
                    segmentStrokeWidth: 2,
                    // percentageInnerCutout: 50,
                    animationSteps: 100,
                    animationEasing: 'easeOutBounce',
                    animateRotate: true,
                    animateScale: false,
                    responsive: true,
                    maintainAspectRatio: true,
                    showScale: true,
                    legend: {
                        display: false,
                        // display: true,
                        position: 'right',
                        // fullWidth: true,
                        labels: {
                            fontSize: 10,
                            boxWidth: 15,
                            padding: 5,
                        },
                    },
                    plugins: {
                        labels: {
                            render: 'value',
                            arc: true,
                            position: 'outside',
                            fontSize: 18,
                            fontStyle: 'bold',
                            fontColor: this.colors,
                            textMargin: -4,
                        },
                    },
                    layout: {
                        padding: {
                            top: 0,
                            bottom: 0,
                            left: 20,
                            right: 20,
                        },
                    },
                },
            }
        },
        components: {
            Pie,
        },
        props: {
            chartData: {
                type: Object,
                default: null,
            },
            colors: {
                type: Array,
                default: null,
            },
            centerLabel: {
                type: Object,
                default: null,
            },
            // selected: {
            //     type: Array,
            //     default: null,
            // },
        },
        mounted() {
        },
    }
</script>

<style lang="stylus" scoped>
    .list-item
        color #555
        list-style none
        text-align left
        display flex
        font-size 9px
        align-items: center;
        // width: 100%
        font-weight bold
        line-height 1.38

    .list-item-disabled
        color #999
        font-weight normal

    .color-box
        margin-right 6px
        width 16px
        height 8px
        // box-shadow 1px 1px 2px #666
        border: 1px solid #999;

    .legend
        display flex
        flex-direction  column
        justify-content  center
        height 100%

    .center-label
        color rgb(109, 126, 134)
        // font-weight bold
        font-size 32px
        position absolute
        cursor default
        pointer-events all
        // text-shadow 1px 1px 4px black

    .center-wrapper
        top 0
        width 100%
        height 100%
        position absolute
        display flex
        justify-content center
        align-items center
        pointer-events: none;

    .pie-wrapper
        position relative

    .v-tooltip__content
        background #222
        color #fff
        border-radius 6px
        padding 5px 10px 4px

</style>
